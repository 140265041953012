import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import styled from "styled-components";
import FAQ from "../components/FrequentlyAskedQ";
import Breadscrum from "../components/Breadscrum";
import Jumbotron from "../components/pohrby/Jumbotron";
import SupportPanel from "../components/SupportPanel";
import RecenzePohreb from "../components/pohrby/RecenzePohreb";
import CoUdelame from "../components/pohrby/CoProVasUdelame";
import Img from "gatsby-image";


import { useMediaQuery } from 'react-responsive';
import SwiperCore, { Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import LinkButton from "../components/pohrby/LinkButton";
import Jo from "../assets/img/pricingCheck.svg";
import Next from "../assets/img/next.svg";
import Prev from "../assets/img/prev.svg";
import UvodniText from "../components/pohrby/UvodniText";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";
import Press from "../components/pohrby/Press";
import KdeFungujeme from "../components/pohrby/KdeFungujeme";
import Postup from "../components/pohrby/Postup";
import StructuredHodnoceni from "../components/pohrby/StucturedHodnoceni";
import Block from "../components/Block";
import RegionsV2 from "../components/brandingV2/RegionsV2";
import LinkButtonV2 from "../components/brandingV2/LinkButtonV2";


SwiperCore.use([Navigation]);


const faqData = [
  {
    otazka: "Mohu si sám vybrat místo rozptylu popela?",
    odpoved: "Ano. Vhodné místa vám doporučíme, ale finální rozhodnutí je na vás.",
  },
  {
    otazka: "Mohu si napsat vlastní smuteční řeč?",
    odpoved: "Smuteční řeč s vámi předem řečník pečlivě připraví. Vytvoříte ji tedy společně pod jeho vedením tak, aby v ní zaznělo vše, co si přejete a zároveň nic, co si nepřejete."
  },
  {
    otazka: "Jak dlouho bude obřad trvat a jak probíhá?",
    odpoved: "Obřady většinou trvají <span>přibližně hodinu</span>. Vždy ale záleží na situaci a typu obřadu. Obvykle obřad začíná řečí, která plynule přejde do vzpomínek pozůstalých. Pokračuje zapojením pozůstalých do konkrétní aktivity (sázením stromu, psaní vzkazů...), poté závěrečnou písní a společným vyprovozením zesnulého. Program ale není pevně daný, a pokud budete chtít jakoukoli změnu, vyhovíme vám."
  },
  {
    otazka: "Co když bude pršet?",
    odpoved: "Vždy máme nachystanou tzv. “mokrou variantu” v případě, že nevyjde počasí. Nemusíte se tedy bát, že bychom obřad zrušili nebo přeložili."
  },
  {
    otazka: "Mohu si vybrat vlastní hudbu?",
    odpoved: "U netradičních obřadů je výběr vlastní hudby samozřejmostí - snažíme se tvořit poslední rozloučení na míru a co nejvíce osobní. Za příplatek je možné domluvit i živou hudbu."
  },
  {
    otazka: "Co všechno cena obsahuje?",
    odpoved: `Cena obsahuje vyzvednutí zesnulého kdekoliv v daném městě, přípravu na kremaci, následný žeh a uložení do dočasné urny. Vyřízení potřebné administrativy a komunikaci s matrikou pro potřeby vyřízení <a href="/slovnik-pojmu/umrtni-list/">úmrtního listu</a>. Dále je v ceně obsažeho plánování obřadu a obřad samotný ve standardní formě, podle popisů jednotlivých druhů výše. U speciálních požadavků doporučujeme poradit se na naší lince 315 558 136, jestli jsou v ceně zahrnuty nebo bude třeba je hradit zvlášť.`
  },
  {
    otazka: "Kdy vás můžu kontaktovat?",
    odpoved: "Kdykoliv. Naše zákaznická linka je vám k dispozici 24 hodin 7 dní v týdnu ve dne i v noci na čísle 315 558 136. Pokud se rozhodne pro kremaci bez obřadu, kontaktujete nás prosím co nejdříve. Je možné, že tak předejdete zbytečným poplatkům od nemocnic a jiných institucí."
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.`
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Jak budu informován v průběhu procesu?",
    odpoved: `O každé změně (vyzvednutí zesnulého, provedení kremace, předpokládané datum doručení <a href="/poradna/kremacni-popel/">urny</a>...) vás budeme informovat telefonicky, případně e-mailem nebo SMS zprávou. Forma komunikace bude jen na vás a na vašich preferencích. Zároveň se můžete kdykoliv obrátit na naši zákaznickou linku.`
  },
  {
    otazka: "Jak se ke mně dostane popel zesnulého?",
    odpoved: "Popel si budete moct sami vyzvednout na pobočce ve vašem okolí, nebo při využití Péče+ vám urnu doručíme osobně až domů. Na termín vyzvednutí vás včas upozorníme."
  },
  {
    otazka: "Kdo může urnu s popelem převzít?",
    odpoved: "Urnu si budete moc převzít pouze vy. Na termín vyzvednutí vás včas upozorníme. Pak se stačí jen řídit otevírací dobou dané pobočky, kde si urnu můžete vyzvednout. Nebo při využití Péče+ vám urnu doručíme osobně až domů."
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do rakve vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže je záměna vyloučena.`
  }
];



const NetradicniPohrebInner = styled.div`
  background: #FBFBFA;
  padding-bottom: 1px;

  .naceneni {
    position: relative;
    padding-top: 80px;
    background: #F3F1EC;

    @media (max-width: 899px) {
      padding-top: 40px;
    }

    h2 {
      position: relative;
      z-index: 1;
      font-size: 32px;
      color: #243A58;
      text-align: center;
      font-family: NewKansas;

      @media (max-width: 899px) {
        font-size: 25px;
        line-height: 35px;
      }
    }

    .pozadi {
      z-index: 0;
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .naceneniInner {
      position: relative;
      z-index: 1;

      .swiper-slide {
        width: fit-content;
        margin-bottom: 80px;
        margin-top: 70px;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;

        &:active {
          cursor: grabbing;
        }

        @media (max-width: 899px) {
          margin-top: 30px;
          margin-bottom: 40px;
        }

        &:last-child {
          margin-right: 180px;

          @media (max-width: 899px) {
            margin-right: 80px;
          }
        }
      }

      .swiper-slide-active {

      }

      .swiper-wrapper {
        left: 90px;

        @media (max-width: 700px) {
          left: 40px;
        }

        @media (max-width: 580px) {
          left: 35px;
        }
      }

      .swiper-button-prev {
        left: 10px;
        background: url(${Prev});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center right;
        height: 220px;
        top: 50%;
        transform: translateY(-50%);
        width: 70px;

        @media (max-width: 899px) {
          display: none;
        }

        &::after {
          content: none;
        }
      }

      .swiper-button-next {
        left: calc(100vw - 80px);
        background: url(${Next});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center left;
        height: 220px;
        top: 50%;
        transform: translateY(-50%);
        width: 70px;

        @media (max-width: 899px) {
          display: none;
        }

        &::after {
          content: none;
        }
      }

      .balicek {
        background: #FFFFFF;
        border: 1px solid #E3E3E3;
        box-shadow: 0 8px 30px 0 rgba(0,0,0,0.12);
        max-width: 480px;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;

        @media (max-width: 700px) {
          width: calc(100vw - 280px);
        }

        @media (max-width: 580px) {
          width: calc(100vw - 90px);
        }

        @media (max-width: 500px) {
          width: calc(100vw - 70px);
        }
        
        

        .mainPic {
          height: 190px;
        }

        .balicekInner {
          width: calc(100% - 60px);
          margin: 0 auto;
          padding-bottom: 30px;

          @media (max-width: 899px) {
            padding-bottom: 25px;
            width: calc(100% - 50px);
          }

          @media (max-width: 340px) {
            padding-bottom: 20px;
            width: calc(100% - 40px);
          }
        }

        h3 {
          font-family: Visuelt-Medium;
          font-size: 24px;
          color: #000000;
          margin-top: 30px;
          margin-bottom: 20px;

          @media (max-width: 899px) {
            margin-top: 25px;
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #585858;
          line-height: 25px;
          margin-bottom: 20px;

          a {
            color: #585858;
          }
        }

        .cena {
          margin-bottom: 30px;

          span {
            &:first-child {
              font-family: Visuelt-Medium;
              font-size: 16px;
              color: #212326;
              margin-right: 8px;
            }

            &:last-child {
              font-family: Visuelt-Medium;
              font-size: 22px;
              color: #212326;
            }
          }
        }

        .cenaPosledni {
          span {
            font-size: 22px !important;
          }
        } 

        .features {
          border-top: 1px solid #D8D8D8;
          padding-top: 30px;

          h4 {
            font-family: Visuelt-Medium;
            font-size: 20px;
            color: #000000;
          }

          ul {
            margin-bottom: 40px;

            li {
              display: flex;
              align-items: center;
              margin-top: 30px;

              img {
                width: 30px;
              }

              span {
                font-family: Visuelt-Regular;
                font-size: 16px;
                color: #000000;
                line-height: 24px;
                margin-left: 15px;
              }
            }
          }
        }
      }
    }
  }
`;






const NetradicniPohreb = ({ data }) => {
  
  
  const isPlus700 = useMediaQuery({
    query: '(min-width: 700px)'
  });

  return (
    <Layout
      subMenuLong
      forcePohrbyMenu
      subMenuItems={[
        {
          name: "Pohřební služba",
          url: "/pohrebni-sluzba/",
        },
        {
          name: "Kde fungujeme?",
          url: "/pobocky/",
        },
        {
          name: "Parte",
          url: "/parte/",
        },
        {
          name: "Kremace bez obřadu",
          url: "/kremace-bez-obradu/",
        },
        {
          name: "Pohřeb s obřadem",
          url: "/pohreb-s-obradem/",
        },
        {
          name: "Netradiční pohřeb",
          url: "/netradicni-pohreb/",
        },
        {
          name: "Pohřeb miminka",
          url: "/pohreb-miminka/",
        },
      ]}
    >
      <SEO
        title="Netradiční pohřby od 25 900 Kč | Goodbye.cz"
        customTitle
        description="Vypuštění lampionů, rozptýlení popela do řeky nebo zasazení stromu. Pomůžeme vám zorganizovat jedinečné rozloučení v přítomnosti vašich blízkých."
        image={"/netradicniOg.png"}
      />

      <StructuredHodnoceni />


      <NetradicniPohrebInner>
     
        <Jumbotron
          h1="Netradiční pohřeb"
          p="Pohřeb nemusí být jen v obřadní síni. Je spoustu jiných způsobů, jak se rozloučit. Pomůžeme vám naplánovat ten pravý pro vás a vaši rodinu."
          pozadi={data.big.childImageSharp.fluid}
          pozadiSmall={data.small.childImageSharp.fluid}
          recenzeText={<>Přestože jsem byla nucena řešit těžkou situaci, mohu s čistým svědomím říct, že služby <span>společnosti Goodbye byly perfektní.</span></>}
          recenzeImg={data.recenzeSample.childImageSharp.fluid}
          recenzeImgSmall={data.recenzeSampleSmall.childImageSharp.fixed}
          zakaznikJmeno={"Květa Č."}
          zakaznikKdo={"Zákaznice"}
          isDark
          breadscrum={
            <Breadscrum
              visible
              stranky={[
                {name: "Domů", url: "/"},
                {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                {name: "Netradiční pohřeb", url: "/netradicni-pohreb/"}
              ]}
            />
          }
        />

        <UvodniText
          h2="Ztratit někoho blízkého je těžké. My to chápeme."
          p="Jsme tu od toho, abychom vás provedli všemi potřebnými kroky. Pomůžeme vám naplánovat jedinečné poslední rozloučení s vaším blízkým. Vytvoříme parte, vyřídíme všechny dokumenty na matrice, pomůžeme vám se smuteční řečí a zorganizujeme i samotný obřad. Vše spolu vyřešíme přes internet a z bezpečí vašeho domova."
          img={data.pohrebUvod.childImageSharp.fixed}
          imgSmall={data.pohrebUvodSmall.childImageSharp.fixed}
        />




        <div className="naceneni">
          <h2>Jaké jsou možnosti?</h2>

          <div className="naceneniInner">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={isPlus700 ? 40 : 15}
              navigation={true}
              observer={true}
              observeParents={true}
            >

              <SwiperSlide key={1}>
                <div className="balicek">
                  <Img className="mainPic" alt="Vypouštění lampionů" fluid={data.lampiony.childImageSharp.fluid} />
                  <div className="balicekInner">
                    <h3>Vypouštění lampionů</h3>
                    <p>Setkání, při kterém budete moci společně s rodinou zavzpomínat na vašeho blízkého během pečlivě nachystané smuteční řeči. Každý potom dostane možnost napsat na lucernu vzkaz, který poputuje do nebe.</p>
                    <div className="cena">
                      <span>za</span>
                      <span>33 990 Kč</span>
                    </div>
                    <div className="features">
                      <h4>V ceně je zahrnuto:</h4>
                      <ul>
                        <li>
                          <img alt="" src={Jo} />
                          <span>Lucerny pro všechny účastníky</span>
                        </li>
                        <li>
                          <img alt="" src={Jo} />
                          <span>Rozptyl popela do řeky nebo podle vašeho výběru</span>
                        </li>
                        <li>
                          <img alt="" src={Jo} />
                          <span>Řečník, židle, deky, deštníky a další drobnosti pro vaše pohodlí</span>
                        </li>
                      </ul>
                      <LinkButtonV2 to="/nabidka/dotaznik/" text="Začít nezávazně plánovat" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>


              <SwiperSlide key={2}>
                <div className="balicek">
                  <Img className="mainPic" alt="Rozloučení u řeky" fluid={data.reka.childImageSharp.fluid} />
                  <div className="balicekInner">
                    <h3>Rozloučení u řeky</h3>
                    <p>Rozloučení na klidném místě poblíž řeky. Součástí obřadu je psaní vzkazů na papírové lodičky, které potom pustíte po proudu. Celý program doprovází <Link to="/poradna/smutecni-rec-rady-tipy/">řečník</Link> a vámi vybraná hudba, zakončen je rozptýlením popela do řeky.</p>
                    <div className="cena">
                      <span>od</span>
                      <span>32 990 Kč</span>
                    </div>
                    <div className="features">
                      <h4>V ceně je zahrnuto:</h4>
                      <ul>
                        <li>
                          <img alt="" src={Jo} />
                          <span>Nachystání obřadního místa a papírových lodiček</span>
                        </li>
                        <li>
                          <img alt="" src={Jo} />
                          <span>Příprava popela na rozptyl</span>
                        </li>
                        <li>
                          <img alt="" src={Jo} />
                          <span>Řečník, židle, deky, deštníky a další drobnosti pro vaše pohodlí</span>
                        </li>
                      </ul>
                      <LinkButtonV2 to="/nabidka/dotaznik/" text="Začít nezávazně plánovat" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>


              <SwiperSlide key={3}>
                <div className="balicek">
                  <Img className="mainPic" alt="Přírodní pohřeb" fluid={data.priroda.childImageSharp.fluid} />
                  <div className="balicekInner">
                    <h3>Přírodní pohřeb</h3>
                    <p>Obřad, který symbolizuje naše spojení s přírodou. Jeho hlavním programem je společné sázení stromu, k jehož kořenům rozptýlíme popel. Druh stromu s vámi předem pečlivě vybíráme podle přáních zesnulého a symboliky.</p>
                    <div className="cena">
                      <span>za</span>
                      <span>35 990 Kč</span>
                    </div>
                    <div className="features">
                      <h4>V ceně je zahrnuto:</h4>
                      <ul>
                        <li>
                          <img alt="" src={Jo} />
                          <span>Strom, který si předem vyberete</span>
                        </li>
                        <li>
                          <img alt="" src={Jo} />
                          <span>Příprava a zasazení stromu v místě vašeho výběru</span>
                        </li>
                        <li>
                          <img alt="" src={Jo} />
                          <span>Řečník, židle, deky, deštníky a další drobnosti pro vaše pohodlí</span>
                        </li>
                      </ul>
                      <LinkButtonV2 to="/nabidka/dotaznik/" text="Začít nezávazně plánovat" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide key={4}>
                <div className="balicek">
                  <Img className="mainPic" alt="" fluid={data.vlastni.childImageSharp.fluid} />
                  <div className="balicekInner">
                    <h3>Máte vlastní představu?</h3>
                    <p>Máte pocit, že ani jedna z nabízených možností není tou pravou? Vyplňte nezávazný formulář, na základě kterého vás budeme kontaktovat. Společně potom vymyslíme rozloučení přesně podle vašich představ.</p>
                    <div className="cena cenaPosledni">
                      <span>Cena dohodou</span>
                    </div>
                    <div className="features">
                      <LinkButtonV2 to="/nabidka/dotaznik/" text="Začít nezávazně plánovat" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>

            </Swiper>
          </div>
        </div>
        




        <Postup
          fotka1={data.postup1.childImageSharp.fluid}
          heading1={"Nezávazně si zavoláme a probereme vaši situaci"}
          text1={"Chápeme, že smrt blízkého je situace, kterou neplánujete. Pokud nám zavoláte, jsme připraveni vaši situaci okamžitě řešit. Nebo se můžete jednoduše zeptat na cokoliv, co vás napadne. Na telefonu jsme pro vás každý den, ve dne v noci."}

          fotka2={data.postup2.childImageSharp.fluid}
          heading2={"Pohřeb s námi naplánujete z bezpečí domova"}
          text2={<>Na plánování budete mít dostatek času. Nemusíte nikam chodit. Vše s vámi vyřešíme po telefonu a on-line (výběr <Link to="/poradna/smutecni-kytice-pohreb/">květin</Link>, <Link to="/rakve/">rakve</Link>, parte a podobně). Zvládnete to tak bez zbytečného tlaku a stresu. Zbytek můžete nechat na nás. Celou dobu je vám naše pohřební služba k dipozici a připravena pomoci.</>}

          fotka3={data.postup3.childImageSharp.fluid}
          heading3={"O vše ostatní se už postaráme my"}
          text3={<>Ať už si vyberete jakýkoliv pohřeb, my jsme tu pro vás, abychom vyřešili formality na matrice, oblečení zesnulého i jeho úpravu, řečníka, místo, výzdobu a další drobnosti. Tak, aby vše proběhlo přesně podle vašich představ. Obřad vám pomůžeme připravit ve spolupráci s <Link to="https://pohrebnipruvodci.cz">Pohřebními průvodci</Link>.</>}
        />
        
        <RecenzePohreb />

        <RegionsV2 />

        <Press />

        <SeVsimPomuzeme />

        <FAQ data={faqData}/>

      </NetradicniPohrebInner>
    </Layout>
  );
}

export const query = graphql`
  query {


    big: file(relativePath: { eq: "alternativniPozadi.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    small: file(relativePath: { eq: "alternativniPozadiSmall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSample: file(relativePath: { eq: "zakaznice3.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSampleSmall: file(relativePath: { eq: "zakaznice3Small.png" }) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    pohrebUvod: file(relativePath: { eq: "alternativniUvod.png" }) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    pohrebUvodSmall: file(relativePath: { eq: "alternativniUvod.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    postup1: file(relativePath: { eq: "postup1.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    postup2: file(relativePath: { eq: "postup2.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    postup3: file(relativePath: { eq: "lodicka.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }














    naceneni: file(relativePath: { eq: "naceneniPozadi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }









    lampiony: file(relativePath: { eq: "lampiony.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    reka: file(relativePath: { eq: "reka.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    priroda: file(relativePath: { eq: "priroda.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    vlastni: file(relativePath: { eq: "vlastni.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

  }
`;

export default NetradicniPohreb;


